import React, {useCallback} from 'react';
import './App.scss';
import ResponsiveAppBar from "./components/responsiveAppBar";

import Particles from "react-tsparticles";
import type { Container, Engine } from "tsparticles-engine";
import {loadFull} from "tsparticles";
import {Card, CardActionArea, CardContent, CardMedia, Typography} from "@mui/material";
import {FaAws, FaCogs, FaJava, FaLaptopCode, FaLinux, FaPhp, FaReact} from "react-icons/fa";
import {SiCplusplus} from "react-icons/si";
import {ContactForm} from "./components/ContactForm";

function App() {

    const particlesInit = useCallback(async (engine: Engine) => {
        console.log(engine);

        // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {
        await console.log(container);
    }, []);

    return (
        <div className="App">
            <div className={"App-header"}>
                <ResponsiveAppBar></ResponsiveAppBar>
            </div>
            <section id={"Home"} className={"mainView"}>

                <Particles
                    id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}
                    style={{position: "absolute", top: 0, left: 0}}
                    options={{
                        fpsLimit: 120,
                        fullScreen: false,
                        interactivity: {
                            events: {
                                onClick: {
                                    enable: true,
                                    mode: "push",
                                },
                                onHover: {
                                    enable: true,
                                    mode: "repulse",
                                },
                                resize: true,
                            },
                            modes: {
                                push: {
                                    quantity: 4,
                                },
                                repulse: {
                                    distance: 200,
                                    duration: 0.4,
                                },
                            },
                        },
                        particles: {
                            color: {
                                value: "#ffffff",
                            },
                            links: {
                                color: "#ffffff",
                                distance: 150,
                                enable: true,
                                opacity: 0.5,
                                width: 1,
                            },
                            collisions: {
                                enable: true,
                            },
                            move: {
                                direction: "none",
                                enable: true,
                                outModes: {
                                    default: "bounce",
                                },
                                random: false,
                                speed: 1,
                                straight: false,
                            },
                            number: {
                                density: {
                                    enable: true,
                                    area: 800,
                                },
                                value: 80,
                            },
                            opacity: {
                                value: 0.25,
                            },
                            shape: {
                                type: "circle",
                            },
                            size: {
                                value: { min: 1, max: 5 },
                            },
                        },
                        detectRetina: true,
                    }}
                />
                <span className={"roleHolder"}>
                    <div className={"role role1"}>Developer</div>
                    <div className={"role role2"}>Software Engineer</div>
                    <div className={"role role3"}>Problem Solver</div>
                    For More Than 20 Years.
                </span>

            </section>
            <section id={"capabilities"} className={"cardHolder"}>
                <Card className={"card"}>
                    <CardActionArea>
                    <CardMedia>
                        <FaLaptopCode size={"5em"}/>
                    </CardMedia>
                    <CardContent>
                        <Typography variant={"h3"} component={"div"}>Full Stack Web Dev</Typography>
                    </CardContent>
                    </CardActionArea>
                </Card>
                <Card className={"card"}>
                    <CardMedia>
                        <FaCogs  size={"5em"}/>
                    </CardMedia>
                    <CardContent>
                        <Typography variant={"h3"}>Systems Engineering</Typography>
                    </CardContent>
                </Card>
                <Card className={"card"}>
                    <CardMedia>
                        <FaJava  size={"5em"}/>
                    </CardMedia>
                    <CardContent>
                        <Typography variant={"h3"}>Java</Typography>
                    </CardContent>
                </Card>
                <Card className={"card"}>
                    <CardMedia>
                        <SiCplusplus  size={"5em"}/>
                    </CardMedia>
                    <CardContent>
                        <Typography variant={"h3"}>C/C++</Typography>
                    </CardContent>
                </Card>
                <Card className={"card"}>
                    <CardMedia>
                        <FaPhp  size={"5em"}/>
                    </CardMedia>
                    <CardContent>
                        <Typography variant={"h3"}>PHP</Typography>
                    </CardContent>
                </Card>
                <Card className={"card"}>
                    <CardMedia>
                        <FaReact  size={"5em"}/>
                    </CardMedia>
                    <CardContent>
                        <Typography variant={"h3"}>React</Typography>
                    </CardContent>
                </Card>
                <Card className={"card"}>
                    <CardMedia>
                        <FaAws  size={"5em"}/>
                    </CardMedia>
                    <CardContent>
                        <Typography variant={"h3"}>AWS</Typography>
                    </CardContent>
                </Card>
                <Card className={"card"}>
                    <CardMedia>
                        <FaLinux  size={"5em"}/>
                    </CardMedia>
                    <CardContent>
                        <Typography variant={"h3"}>Linux</Typography>
                    </CardContent>
                </Card>
            </section>
            <section id={"contact_me"} className={"contactForm"}>
                <ContactForm />
            </section>
        </div>
    );
}

export default App;
